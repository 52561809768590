import { stytch } from '@/auth/stytch';
import { PageTransitionLayout } from '@/components/PageTransitionLayout';
import { emitHeapEvents } from '@/metrics/emitHeapEvents';
import { emitHeapUserCreatedEvent } from '@/metrics/emitHeapUserCreatedEvent';
import { setHeapProps } from '@/metrics/setHeapEventProps';
import { useCase } from '@/routes/hooks/useCase';
import { useCompletedCheckout } from '@/routes/hooks/useCompletedCheckout';
import { useFields } from '@/routes/hooks/useFields';
// import { usePartnership } from '@/routes/hooks/usePartnership';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { clearCaseStateAndLocalStorage } from '@/utils/clearCaseStateAndLocalStorage';
import { DASHBOARD_ROUTES, getDashboardURL } from '@/utils/dashboardUrl';
import { isXLContainer } from '@/utils/isXLContainer';
import { isMilestone } from '@/utils/showLayoutElements';
import {
  isCheckoutStep,
  isLoaderProductStep,
  isMilestoneStep,
  isProductStep,
  isValidationStep,
} from '@/utils/stepUtils';
import { AppShell, Container, useMantineTheme } from '@mantine/core';
import { Outlet, useNavigate, useParams, useRouter } from '@tanstack/router';
import { useCallback, useEffect } from 'react';
import { LayoutHeader } from '../components/LayoutHeader';
import { pushCustomEvent } from '../metrics/pushCustomEvent';
import { usePreviousCase } from './hooks/usePreviousCase';
import { usePreviousFields } from './hooks/usePreviousFields';

export const Layout = () => {
  const theme = useMantineTheme();
  const { stepId } = useParams();
  const navigate = useNavigate();

  const nextFields = useFields();
  const prevFields = usePreviousFields(nextFields);
  const nextCase = useCase();
  const router = useRouter();
  const prevCase = usePreviousCase(nextCase);
  const isMdBreakpoint = useMdBreakpoint();
  const { isCheckoutComplete } = useCompletedCheckout();
  // const partnership = usePartnership();

  // useBeacon();

  const redirectOnCheckoutComplete = useCallback(async () => {
    const sessionToken = stytch.session.getTokens()?.session_token;
    if (sessionToken) {
      const tokens = stytch.session.getTokens();

      window.location.replace(
        `${getDashboardURL()}/${DASHBOARD_ROUTES.sessionAuthenticate}?sessionToken=${
          tokens?.session_token ?? ''
        }`,
      );
    } else {
      await clearCaseStateAndLocalStorage();
      navigate({ to: '/' });
    }
  }, [navigate]);

  useEffect(() => {
    // Push Google Tag Manager custom event on case state change
    pushCustomEvent({ prevFields, nextFields });
  }, [nextFields, prevFields]);

  useEffect(() => {
    setHeapProps(nextCase);
  }, [nextCase]);

  useEffect(() => {
    emitHeapUserCreatedEvent(prevCase, nextCase);
  }, [prevCase, nextCase]);

  useEffect(() => {
    emitHeapEvents(prevFields, nextFields);
  }, [prevFields, nextFields]);

  // useEffect(() => {
  //   // clearCaseStateAndLocalStorage();
  //   if (partnership) {
  //     router.navigate({
  //       to: '/',
  //       search: (currentSearch) => ({
  //         ...currentSearch,
  //         partnership,
  //       }),
  //     });
  //   } else {
  //     router.navigate({ to: '/info' });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (stepId && stepId !== StepName.bill_size) {
  //     clearCaseStateAndLocalStorage();
  //     router.navigate({ to: '/info' });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stepId]);

  useEffect(() => {
    if (!stepId && isCheckoutComplete && router.state.currentLocation.pathname === '/') {
      redirectOnCheckoutComplete();
    }
  }, [
    stepId,
    isCheckoutComplete,
    navigate,
    redirectOnCheckoutComplete,
    router.state.currentLocation,
  ]);

  const showProgressBar = !!(
    stepId &&
    !isValidationStep(stepId) &&
    !isMilestoneStep(stepId) &&
    !isCheckoutStep(stepId)
  );

  const isInfo = router.state.currentLocation.href === '/info';

  return (
    <AppShell
      header={<LayoutHeader showProgressBar={showProgressBar} />}
      // Mantine calculates the padding to put on the child container based on the header height
      // This forces the use of positioning instead.
      // https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/AppShell/AppShell.styles.ts
      pos="relative"
      styles={{
        main: {
          paddingTop: showProgressBar
            ? isMdBreakpoint
              ? theme.other.rootHeaderWithProgressHeight.md
              : theme.other.rootHeaderWithProgressHeight.sm
            : isMdBreakpoint
            ? theme.other.rootHeaderHeight.md
            : theme.other.rootHeaderHeight.sm,
          paddingBottom: isMdBreakpoint ? '3.75rem' : '5rem',
          maxWidth: '100vw',
          overflowX: 'hidden',
          background: isInfo ? '#2e193e' : 'transparent',
        },
      }}
      bg="minor.1"
    >
      <PageTransitionLayout
        key={stepId}
        noAnimation={
          isMilestone(stepId ?? '') ||
          isCheckoutStep(stepId ?? '') ||
          isProductStep(stepId ?? '') ||
          isLoaderProductStep(stepId ?? '')
        }
      >
        <>
          <Container
            pos={stepId && isMilestone(stepId) ? 'inherit' : 'relative'}
            size={stepId && isXLContainer(stepId) ? theme.breakpoints.xl : theme.breakpoints.md}
            px={{
              base: theme.other.rootContainerPaddingX.sm,
              md: theme.other.rootContainerPaddingX.md,
            }}
            pb={{
              base: theme.other.rootContainerPaddingBottom.sm,
              md: theme.other.rootContainerPaddingBottom.md,
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            style={{
              maxWidth: stepId && isMilestone(stepId) ? 1300 : 896,
              minHeight: stepId && isMilestone(stepId) ? '100%' : 'auto',
              boxSizing: 'content-box',
              marginTop: 15,
            }}
          >
            {/* On navigation from previous cmp to the same next cmp we want to un-mount previous child component to have empty cmp state. 
          Example: previous and next cmp are ChoiceStep. */}
            <Outlet key={stepId} />
          </Container>
        </>
      </PageTransitionLayout>
    </AppShell>
  );
};
